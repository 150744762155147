import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: [],
};

export const getSliders = createAsyncThunk (
    'sliders/getSliders',
    async (thunkAPI) => {
        const res = await fetch(`${baseURL}/sliders`).then((data)=> data.json());
        return res;
    }
)
export const slidersSlice = createSlice({
  name: 'sliders',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  extraReducers: (builder) => {
      builder.addCase(getSliders.pending, (state) =>{
          state.loading = true;
      })
      builder.addCase(getSliders.fulfilled, (state, action)=> {
          state.loading = false;
          state.data = action.payload.results;
      })
      builder.addCase(getSliders.rejected, (state) => {
          state.loading = false;
      })
  },
});

export const slidersSelector = (state) => state.sliders;

export default slidersSlice.reducer;