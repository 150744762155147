import { Link } from "react-router-dom";

const LinkWithTextArrow = ({to,text,className}) => (
	<Link to={to} className={"w-full mt-3 flex items-center transition-all py-4 text-red-500 text-sm font-semibold "+className}>
		<span className="mr-2">{text??'VIEW MORE'}</span>
		<svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
			<path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
		</svg>
	</Link>
)
export default LinkWithTextArrow;