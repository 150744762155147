import Animate from "./Animate";
import Container from "./containers/Container";
import founderImage from "../assets/images/founder.png";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
    const location = useLocation();
    const slug = location.pathname.split("/")[1];
    return (
        <Animate animateIn="fadeIn">
            <footer className="w-full flex flex-col print:hidden">
                <div className="bg-white">
                    <Container className="grid grid-cols-1 md:grid-cols-5 pt-9 pb-14 font-wwf px-4 xl:px-0 gap-y-10 lg:gap-y-0">
                        <div className="pr-5">
                            {slug === "youth-sounding-board" ? (
                                <p className=" text-xl text-blue-500">
                                    EXPLORE
                                </p>
                            ) : slug === "climate-fellowship" ? (
                                <p className=" text-xl text-green-500">
                                    EXPLORE
                                </p>
                            ) : (
                                <p className=" text-xl text-red-500">EXPLORE</p>
                            )}
                            <ul className="font-normal mt-2 md:mt-5 text-lg">
                                <li>
                                    <Link to="/climate-smart-entrepreneurship">
                                        Climate Smart Entrepreneurship
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/climate-fellowship">
                                        Climate Fellowship
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/youth-sounding-board">
                                        Youth Sounding Board
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="pr-5">
                            {slug === "youth-sounding-board" ? (
                                <p className=" text-xl text-blue-500">
                                    GET TO KNOW US
                                </p>
                            ) : slug === "climate-fellowship" ? (
                                <p className=" text-xl text-green-500">
                                    GET TO KNOW US
                                </p>
                            ) : (
                                <p className=" text-xl text-red-500">
                                    GET TO KNOW US
                                </p>
                            )}
                            <ul className="font-normal mt-2 md:mt-5 text-lg">
                                <li>
                                    <Link to="/about-us">About NYCAGG</Link>
                                </li>
                                <li>
                                    <Link to="/about-team">Meet the team</Link>
                                </li>
                                {/* <li><Link to="#">Blog</Link></li> */}
                            </ul>
                        </div>
                        <div className="pr-5">
                            {slug === "youth-sounding-board" ? (
                                <p className="text-xl text-blue-500">
                                    CONSORTIUM PARTNER
                                </p>
                            ) : slug === "climate-fellowship" ? (
                                <p className="text-xl text-green-500">
                                    CONSORTIUM PARTNER
                                </p>
                            ) : (
                                <p className="text-xl text-red-500">
                                    CONSORTIUM PARTNER
                                </p>
                            )}
                            <ul className="font-normal mt-2 md:mt-5 text-lg">
                                <li>
                                    <a
                                        href="https://youthinnovationlab.org/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Youth Innovation Lab
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://restlessdevelopment.org/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Restless Development
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://creasion.org/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        CREASION
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="http://www.cdms.org.np/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        CDMS
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="pr-5">
                            {slug === "youth-sounding-board" ? (
                                <p className="text-xl text-blue-500">
                                    FINANCIAL SUPPORT
                                </p>
                            ) : slug === "climate-fellowship" ? (
                                <p className="text-xl text-green-500">
                                    FINANCIAL SUPPORT
                                </p>
                            ) : (
                                <p className="text-xl text-red-500">
                                    FINANCIAL SUPPORT
                                </p>
                            )}
                            <div className="mt-5 w-20">
                                <img src={founderImage} alt="Founder Logo" />
                            </div>
                        </div>

                        <div className="">
                            {slug === "youth-sounding-board" ? (
                                <p className="text-xl text-blue-500">
                                    CONNECT WITH US
                                </p>
                            ) : slug === "climate-fellowship" ? (
                                <p className="text-xl text-green-500">
                                    CONNECT WITH US
                                </p>
                            ) : (
                                <p className="text-xl text-red-500">
                                    CONNECT WITH US
                                </p>
                            )}
                            <ul className="font-normal mt-2 md:mt-5 list-inline">
                                <li className="mr-2">
                                    {slug === "youth-sounding-board" ? (
                                        <a
                                            target="_blank"
                                            href="https://www.facebook.com/YILabNepal/"
                                            className="text-xs bg-blue-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-blue-400"
                                        >
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </a>
                                    ) : slug === "climate-fellowship" ? (
                                        <a
                                            target="_blank"
                                            href="https://www.facebook.com/YILabNepal/"
                                            className="text-xs bg-green-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-green-400"
                                        >
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </a>
                                    ) : (
                                        <a
                                            target="_blank"
                                            href="https://www.facebook.com/YILabNepal/"
                                            className="text-xs bg-red-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-red-400"
                                        >
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </a>
                                    )}
                                </li>
                                <li className="mr-2">
                                    {slug === "youth-sounding-board" ? (
                                        <a
                                            target="_blank"
                                            href="https://mobile.twitter.com/yilabnepal"
                                            className="text-xs bg-blue-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-blue-400"
                                        >
                                            <i className="fa-brands fa-twitter"></i>
                                        </a>
                                    ) : slug === "climate-fellowship" ? (
                                        <a
                                            target="_blank"
                                            href="https://mobile.twitter.com/yilabnepal"
                                            className="text-xs bg-green-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-green-400"
                                        >
                                            <i className="fa-brands fa-twitter"></i>
                                        </a>
                                    ) : (
                                        <a
                                            target="_blank"
                                            href="https://mobile.twitter.com/yilabnepal"
                                            className="text-xs bg-red-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-red-400"
                                        >
                                            <i className="fa-brands fa-twitter"></i>
                                        </a>
                                    )}
                                </li>

                                <li className="mr-2">
                                    {slug === "youth-sounding-board" ? (
                                        <a
                                            target="_blank"
                                            href="https://NYCAGG@youthinnovationlab.org"
                                            className="text-xs bg-blue-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-blue-400"
                                        >
                                            <i className="fa-solid fa-envelope"></i>
                                        </a>
                                    ) : slug === "climate-fellowship" ? (
                                        <a
                                            target="_blank"
                                            href="https://NYCAGG@youthinnovationlab.org"
                                            className="text-xs bg-green-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-green-400"
                                        >
                                            <i className="fa-solid fa-envelope"></i>
                                        </a>
                                    ) : (
                                        <a
                                            target="_blank"
                                            href="https://NYCAGG@youthinnovationlab.org"
                                            className="text-xs bg-red-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-red-400"
                                        >
                                            <i className="fa-solid fa-envelope"></i>
                                        </a>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </Container>
                </div>

                <div className="bg-black">
                    <Container className="grid lg:flex items-center text-white text-sm py-4 px-4 lg:px-0">
                        <p className="pr-10 font-normal text-xs">
                            Disclaimer: This website is produced with the
                            financial support of the European Union. Its content
                            are the sole responsibility of YI-Lab and its
                            consortium partners and do not necessarily reflect
                            the views of the European Union.
                        </p>

                        <p className="basis-1/4 text-left lg:text-right font-semibold mt-3 lg:mt-0">
                            Copyright&copy; 2022 | NYCAGG 
                        </p>
                    </Container>
                </div>
            </footer>
        </Animate>
    );
};

export default Footer;
