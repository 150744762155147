import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: [],
};

export const getDistricts = createAsyncThunk (
    'districts/getDistricts',
    async (thunkAPI) => {
        const res = await fetch(`${baseURL}/district/?limit=-1`).then((data)=> data.json());
        return res;
    }
)
export const districtsSlice = createSlice({
  name: 'districts',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  extraReducers: (builder) => {
      builder.addCase(getDistricts.pending, (state) =>{
          state.loading = true;
      })
      builder.addCase(getDistricts.fulfilled, (state, action)=> {
          state.loading = false;
          state.data = action.payload.results;
      })
      builder.addCase(getDistricts.rejected, (state) => {
          state.loading = false;
      })
  },
});

export const districtsSelector = (state) => state.districts;

export default districtsSlice.reducer;