import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getMemberDetails = createAsyncThunk(
    "memberDetails/getMemberDetails",
    async (thunkAPI) => {
        const res = await fetch(`${baseURL}/ysb/?ordering=year`).then((data) =>
            data.json()
        );
        return res;
    }
);
export const memberDetailsSlice = createSlice({
    name: "memberDetails",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMemberDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getMemberDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.results;
        });
        builder.addCase(getMemberDetails.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const memberDetailsSelector = (state) => state.memberDetails;

export default memberDetailsSlice.reducer;
