import React, { useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import WebRoute from "./components/WebRoute";

function App() {
    const map = useRef(null)
    return (
        <BrowserRouter>
            <Header />
            <main className="min-h-[80vh]">
                <WebRoute />
            </main>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
