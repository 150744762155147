import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getProjectGuideline = createAsyncThunk(
    "projectGuideline/getProjectGuideline",
    async (projectType, thunkAPI) => {
        const res = await fetch(
            `${baseURL}/project-guidelines?project_type=${projectType}`
        ).then((data) => data.json());
        return res;
    }
);
export const projectGuidelineSlice = createSlice({
    name: "projectGuideline",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProjectGuideline.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProjectGuideline.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.results;
        });
        builder.addCase(getProjectGuideline.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const projectGuidelineSelector = (state) => state.projectGuideline;

export default projectGuidelineSlice.reducer;
