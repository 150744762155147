import "../assets/css/app.css";

import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Container from "./containers/Container";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getProvinces } from "../features/provinces/provincesSlice";
import { getDistricts } from "../features/districts/districtsSlice";
import { getMunicipalities } from "../features/municipalities/municipalitiesSlice";
import logo from "../../src/assets/images/main-logo.svg";

export function InfoBox(props) {
    const ref = useRef(null);
    const { onClickOutside, mainDivClicked } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        if (!mainDivClicked) {
            document.addEventListener("click", handleClickOutside, true);
            return () => {
                document.removeEventListener("click", handleClickOutside, true);
            };
        } else {
            document.addEventListener("click", handleClickOutside, false);
            return () => {
                document.removeEventListener(
                    "click",
                    handleClickOutside,
                    false
                );
            };
        }
    }, [onClickOutside]);

    if (!props.show) return null;

    return (
        <div ref={ref} className="info-box" style={{ display: "none" }}></div>
    );
}

const Header = () => {
    const [openSidebar, setOpenSidebar] = useState(false);
    const [clickedAbout, setClickedAbout] = useState(false);
    const [clickedMainAboutDiv, setClickedMainAboutDiv] = useState(false);
    const nav = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const slug = location.pathname.split("/")[1];
    const subSlug = location.pathname.split("/")[2];
    const sidebarToggleHandler = () => {
        setOpenSidebar(!openSidebar);
        if (!openSidebar) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "scroll";
        }
    };

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "instant"
        });
        setOpenSidebar(false);
    }, [nav]);

    // Accessing redux values
    const { data } = useSelector((state) => state.provinces);

    // Call the api as this where needed
    useEffect(() => {
        dispatch(getProvinces());
        dispatch(getDistricts());
        dispatch(getMunicipalities());
    }, [dispatch]);
    return (
        <header className="bg-black h-20 print:hidden">
            <Container className="relative flex justify-end items-center">
                {slug === "youth-sounding-board" ? (
                    <Link
                        to="/"
                        className="logo bg-blue-500 z-50 flex justify-center"
                    >
                        <img src={logo} alt="" />
                        <span className="text-white font-wwf text-3xl absolute top-[25%] left-24">TheYouthCAN</span>
                    </Link>
                ) : slug === "climate-fellowship" ? (
                    <Link
                        to="/"
                        className="logo bg-green-500 z-50 flex justify-center"
                    >
                        <img src={logo} alt="" />
                        <span className="text-white font-wwf text-3xl absolute top-[25%] left-24">TheYouthCAN</span>
                    </Link>
                ) : (
                    <Link
                        to="/"
                        className="logo bg-red-500 z-50 flex justify-center"
                    >
                        <img src={logo} alt="" />
                        <span className="text-white font-wwf text-3xl absolute top-[25%] left-[90px]">TheYouthCAN</span>
                    </Link>
                )}
                <nav
                    className={
                        "primary-menu z-50 overflow-visible " +
                        (openSidebar ? "expanded" : "")
                    }
                >
                    {slug === "youth-sounding-board" ? (
                        <button
                            className="block lg:hidden text-white mr-4 bg-blue-400 rounded-full p-2 fixed top-4 right-4 fill-zinc-500"
                            onClick={sidebarToggleHandler}
                        >
                            {openSidebar ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-7 w-7"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    ) : slug === "climate-fellowship" ? (
                        <button
                            className="block lg:hidden text-white mr-4 bg-green-400 rounded-full p-2 fixed top-4 right-4 fill-zinc-500"
                            onClick={sidebarToggleHandler}
                        >
                            {openSidebar ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-7 w-7"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    ) : (
                        <button
                            className="block lg:hidden text-white mr-4 bg-red-400 rounded-full p-2 fixed top-4 right-4 fill-zinc-500"
                            onClick={sidebarToggleHandler}
                        >
                            {openSidebar ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-7 w-7"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    )}

                    <ul className="text-white nav-list overflow-visible">
                        <li
                            className={
                                slug === "climate-smart-entrepreneurship" &&
                                !subSlug &&
                                "active_header_element"
                            }
                        >
                            <Link to="/climate-smart-entrepreneurship">
                                CLIMATE SMART ENTREPRENEURSHIP
                            </Link>
                        </li>
                        <li
                            className={
                                slug === "climate-fellowship" &&
                                !subSlug &&
                                "active_header_element before:border-green-500 after:border-t-green-500"
                            }
                        >
                            <Link
                                to="/climate-fellowship"
                                onClick={() =>
                                    (document.body.style.overflow = "scroll")
                                }
                            >
                                CLIMATE FELLOWSHIP
                            </Link>
                        </li>
                        <li
                            className={
                                slug === "youth-sounding-board" &&
                                !subSlug &&
                                "active_header_element before:border-blue-500 after:border-t-blue-500"
                            }
                        >
                            <Link
                                to="/youth-sounding-board"
                                onClick={() =>
                                    (document.body.style.overflow = "scroll")
                                }
                            >
                                YOUTH SOUNDING BOARD
                            </Link>
                        </li>
                        <li
                            className={
                                slug === "blogs" && "active_header_element"
                            }
                        >
                            <Link to="/blogs">BLOG</Link>
                        </li>
                        <li
                            className={`group relative overflow-visible  ${
                                slug === "climate-fellowship" &&
                                subSlug === "about"
                                    ? "active_header_element before:border-green-500 after:border-t-green-500"
                                    : (slug === "about-us" ||
                                          slug === "about-team" ||
                                          subSlug === "about") &&
                                      "active_header_element"
                            }`}
                        >
                            <Link
                                to="#"
                                onClick={() => {
                                    setClickedMainAboutDiv(
                                        !clickedMainAboutDiv
                                    );
                                    setClickedAbout(!clickedAbout);
                                    document.body.style.overflow = "scroll";
                                }}
                            >
                                <InfoBox
                                    show={clickedAbout}
                                    onClickOutside={() => {
                                        setClickedAbout(false);
                                        setClickedMainAboutDiv(false);
                                    }}
                                    mainDivClicked={clickedMainAboutDiv}
                                />
                                <span className="mr-1">ABOUT</span>
                                <span>
                                    <IoMdArrowDropdown />
                                </span>
                            </Link>
                            {clickedAbout ? (
                                <ul className="primary-sub-menu max-w-[200px]">
                                    <li className="">
                                        <Link
                                            to="/about-us"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            NYCAGG
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/climate-smart-entrepreneurship/about"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Climate Smart​ Entrepreneurship​
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/climate-fellowship/about"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Climate Fellowship
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/youth-sounding-board/about"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Youth Sounding Board
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/about-team"
                                            className="!text-left"
                                            onClick={() =>
                                                (document.body.style.overflow =
                                                    "scroll")
                                            }
                                        >
                                            Our Team
                                        </Link>
                                    </li>
                                </ul>
                            ) : (
                                ""
                            )}
                        </li>

                        <li>
                            {slug === "youth-sounding-board" ? (
                                <Link
                                    to="/climate-smart-entrepreneurship/idea-submit"
                                    className="relative bg-blue-500 px-5 py-2 rounded-md font-sans text-sm btn transition-all hover:bg-blue-600 active:bg-blue-700 text-white"
                                >
                                    SUBMIT IDEA
                                </Link>
                            ) : slug === "climate-fellowship" ? (
                                <Link
                                    to="/climate-smart-entrepreneurship/idea-submit"
                                    className="relative bg-green-500 px-5 py-2 rounded-md font-sans text-sm btn transition-all hover:bg-green-600 active:bg-green-700 text-white"
                                >
                                    SUBMIT IDEA
                                </Link>
                            ) : (
                                <Link
                                    to="/climate-smart-entrepreneurship/idea-submit"
                                    className="relative bg-red-500 px-5 py-2 rounded-md font-sans text-sm btn transition-all hover:bg-red-600 active:bg-red-700 text-white"
                                >
                                    SUBMIT IDEA
                                </Link>
                            )}
                        </li>
                    </ul>
                </nav>
            </Container>
        </header>
    );
};

export default Header;
