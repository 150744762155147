import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getFaq = createAsyncThunk("faq/getFaq", async (thunkAPI) => {
    const res = await fetch(`${baseURL}/faq?limit=-1`).then((data) =>
        data.json()
    );
    return res;
});
export const faqSlice = createSlice({
    name: "faq",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFaq.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getFaq.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.results;
        });
        builder.addCase(getFaq.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const faqSelector = (state) => state.faq;

export default faqSlice.reducer;
