import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: [],
};

export const getAboutUs = createAsyncThunk (
    'aboutUs/getAboutUs',
    async (thunkAPI) => {
        const res = await fetch(`${baseURL}/about-us`).then((data)=> data.json());
        return res;
    }
)
export const aboutUsSlice = createSlice({
  name: 'aboutUs',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  extraReducers: (builder) => {
      builder.addCase(getAboutUs.pending, (state) =>{
          state.loading = true;
      })
      builder.addCase(getAboutUs.fulfilled, (state, action)=> {
          state.loading = false;
          state.data = action.payload.results;
      })
      builder.addCase(getAboutUs.rejected, (state) => {
          state.loading = false;
      })
  },
});

export const aboutUsSelector = (state) => state.aboutUs;

export default aboutUsSlice.reducer;