import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getMentor = createAsyncThunk(
    "mentor/getMentors",
    async (thunkAPI) => {
        const res = await fetch(`${baseURL}/mentor/`).then((data) =>
            data.json()
        );
        return res;
    }
);
export const mentorSlice = createSlice({
    name: "mentors",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMentor.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getMentor.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.results;
        });
        builder.addCase(getMentor.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const mentorSelector = (state) => state.mentors;

export default mentorSlice.reducer;
