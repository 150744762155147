import React, { useEffect } from "react";
import PageHeading from "../../components/heading/PageHeading";
import Container from "../../components/containers/Container";
import Animate from "../../components/Animate";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HtmlReactParser from "html-react-parser";
import { getTeamMember } from "../../features/teamMembers/teamMemberSlice";
import { saveData, teamOrBoardOrMentor } from "../../features/team/team";
import Loader from "../../components/loader/Loader";

const TeamDetails = () => {
    const { id } = useParams();
    const { teamBoardSelection } = useSelector((state) => state.team);
    const { data: teamMemberData, loading } = useSelector(
        (state) => state.teamMember
    );
    const { data: MentorData } = useSelector((state) => state.mentor);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTeamMember(id));
    }, []);
    const { email, facebook_link, twitter_link } = teamMemberData;

    return (
        <>
            <PageHeading
                heading="Our Team"
                description={
                    "NYCAGG project team comprises young and dynamic individuals with a passion for sustainability, youth engagement, DRRM and CCA,governance, and community resilience. The project team consists of more than 50% females and the majority are under 40 years of age."
                }
            />

            {loading ? (
                <Loader />
            ) : (
                <Animate animateIn="fadeIn">
                    <section className="bg-gray-200">
                        <Container className="py-5">
                            <div className="flex m-5 ml-0">
                                <Link to="/about-team">
                                    <div
                                        className={
                                            "m-2 cursor-pointer teamBoardSelect"
                                        }
                                    >
                                        {" "}
                                        <h3
                                            onClick={() => {
                                                dispatch(saveData("Our Team​"));
                                                dispatch(
                                                    teamOrBoardOrMentor("Team")
                                                );
                                            }}
                                        >
                                            Team
                                        </h3>
                                    </div>
                                </Link>
                                {MentorData && MentorData.length ? (
                                    <Link to="/about-mentor">
                                        <div className={"m-2 cursor-pointer "}>
                                            <h3
                                                onClick={() => {
                                                    dispatch(
                                                        saveData("Mentors")
                                                    );
                                                    dispatch(
                                                        teamOrBoardOrMentor(
                                                            "Mentors"
                                                        )
                                                    );
                                                }}
                                            >
                                                Mentors
                                            </h3>
                                        </div>
                                    </Link>
                                ) : (
                                    ""
                                )}
                                <Link to="/about-pat-member">
                                    <div className={"m-2 cursor-pointer "}>
                                        <h3
                                            onClick={() => {
                                                dispatch(
                                                    saveData("PAT Members")
                                                );
                                                dispatch(
                                                    teamOrBoardOrMentor(
                                                        "PAT Members"
                                                    )
                                                );
                                            }}
                                        >
                                            PAT Members
                                        </h3>
                                    </div>
                                </Link>
                            </div>
                            <div className="ml-4 mr-4 flex opacity-60 text-sm">
                                <Link
                                    to="/about-us"
                                    className="hover:cursor-pointer hover:underline"
                                >
                                    {" "}
                                    <p>About &nbsp;>&nbsp;</p>
                                </Link>
                                <Link
                                    to="/about-team"
                                    className="hover:cursor-pointer hover:underline"
                                >
                                    <p>{teamBoardSelection}&nbsp;>&nbsp;</p>
                                </Link>
                                <p>{teamMemberData.name}</p>
                            </div>

                            <div className="ml-4 mr-4 py-10 max-w-7xl flex-col lg:flex lg:flex-row">
                                <div className="mr-0 mb-10 lg:mr-10">
                                    <img
                                        src={
                                            teamMemberData &&
                                            teamMemberData.picture
                                        }
                                        alt="team"
                                        className="max-w-full w-96 h-96 object-contain lg:max-w-none"
                                        loading="lazy"
                                    />
                                    {email || facebook_link || twitter_link ? (
                                        <div className="flex items-center justify-end mt-2">
                                            <h2 className="ml-1 mr-2 opacity-60 font-wwf text-lg">
                                                GET IN TOUCH
                                            </h2>
                                            {facebook_link ? (
                                                <a
                                                    href={`${facebook_link}`}
                                                    target="_blank"
                                                >
                                                    <div className="ml-1 mr-1 cursor-pointer text-xs bg-red-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-red-400">
                                                        <i className="fa-brands fa-facebook-f"></i>
                                                    </div>
                                                </a>
                                            ) : (
                                                ""
                                            )}
                                            {twitter_link ? (
                                                <a
                                                    href={`${twitter_link}`}
                                                    target="_blank"
                                                >
                                                    <div className="ml-1 mr-1 cursor-pointer text-xs bg-red-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-red-400">
                                                        <i className="fa-brands fa-twitter"></i>
                                                    </div>
                                                </a>
                                            ) : (
                                                ""
                                            )}
                                            {email ? (
                                                <a
                                                    href={`mailto:${email}`}
                                                    target="_blank"
                                                >
                                                    <div className="ml-1 mr-1 cursor-pointer text-xs bg-red-500 text-white h-7 w-7 rounded-full flex justify-center items-center hover:bg-red-400">
                                                        <i className="fa-solid fa-envelope"></i>
                                                    </div>
                                                </a>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div>
                                    <h1>
                                        {teamMemberData && teamMemberData.name}
                                    </h1>
                                    <h4 className="mb-5 opacity-60">
                                        {teamMemberData &&
                                            teamMemberData.designation}
                                    </h4>
                                    {teamMemberData &&
                                    teamMemberData.description ? (
                                        <div className="font-graphik_regular opacity-90">
                                            {" "}
                                            {HtmlReactParser(
                                                teamMemberData.description
                                            )}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </Container>
                    </section>
                </Animate>
            )}
        </>
    );
};

export default TeamDetails;
