import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getTeamMember = createAsyncThunk(
    "teamMembers/getTeamMember",
    async (slug, thunkAPI) => {
        const res = await fetch(`${baseURL}/team-member/${slug}`).then((data) =>
            data.json()
        );
        return res;
    }
);
export const teamMemberSlice = createSlice({
    name: "teamMembers",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTeamMember.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTeamMember.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getTeamMember.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const teamMemberSelector = (state) => state.teamMember;

export default teamMemberSlice.reducer;
