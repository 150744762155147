import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: [],
};

export const getOurReach = createAsyncThunk (
    'ourReach/getOurReach',
    async (thunkAPI) => {
        const res = await fetch(`${baseURL}/our-reach`).then((data)=> data.json());
        return res;
    }
)
export const ourReachSlice = createSlice({
  name: 'ourReach',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  extraReducers: (builder) => {
      builder.addCase(getOurReach.pending, (state) =>{
          state.loading = true;
      })
      builder.addCase(getOurReach.fulfilled, (state, action)=> {
          state.loading = false;
          state.data = action.payload.results;
      })
      builder.addCase(getOurReach.rejected, (state) => {
          state.loading = false;
      })
  },
});

export const ourReachSelector = (state) => state.ourReach;

export default ourReachSlice.reducer;