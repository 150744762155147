import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: [],
    filter: { sdg:"", minFund: undefined, maxFund: undefined }
};

export const getIdea = createAsyncThunk (
    'idea/getIdea',
    async ({offset, thunkAPI}, { getState }) => {
        const state = getState();
        const res = await fetch(`${baseURL}/idea?is_verified=true&page=${offset}&fund_seeking_min=${state.idea.filter.minFund === undefined ? "" : state.idea.filter.minFund}&fund_seeking_max=${state.idea.filter.maxFund === undefined ? "" : state.idea.filter.maxFund}&expand=sdg_goal,sdg_target&sdg_goal__short_title=${state.idea.filter.sdg ?? ''}`).then((data)=> data.json());
        return res;
    }
)

export const ideaSlice = createSlice({
  name: 'idea',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
        setFilter: (state, action) => {
            state.filter.sdg = action.payload?.sdgValue;
            state.filter.minFund = action.payload?.fundSeeking[0];
            state.filter.maxFund = action.payload?.fundSeeking[1];
        },
  },
  extraReducers: (builder) => {
        builder.addCase(getIdea.pending, (state) =>{
            state.loading = true;
        })
        builder.addCase(getIdea.fulfilled, (state, action)=> {
            state.loading = false;
            state.data = action.payload;
        })
        builder.addCase(getIdea.rejected, (state) => {
            state.loading = false;
        })
  },
});
export const { setFilter } = ideaSlice.actions;

export const ideaSelector = (state) => state.idea;

export default ideaSlice.reducer;