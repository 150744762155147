import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getMentorDetails = createAsyncThunk(
    "mentor/getMentorDetails",
    async (slug, thunkAPI) => {
        const res = await fetch(`${baseURL}/mentor/?slug_char=${slug}`).then(
            (data) => data.json()
        );
        return res;
    }
);
export const mentorDetailSlice = createSlice({
    name: "mentorDetails",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMentorDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getMentorDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getMentorDetails.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const mentorDetailSelector = (state) => state.mentorDetails;

export default mentorDetailSlice.reducer;
