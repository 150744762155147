import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Animate from "../Animate";
import Container from "../containers/Container";
import LinkWithTextArrow from "../links/LinkWithTextArrow";
/**
 *
 * @param {children} Component  !! only pass gradient with absolute div and Background Image in this parameter
 * @param {heading} String
 * @param {description} String
 * @param {link} String
 * @returns
 */
const PageHeading = ({ children, heading, description, link }) => {
    const [pathName, setPathName] = useState("");
    const { pathname } = useLocation();

    useEffect(() => {
        const path = pathname.substring(1);
        setPathName(path);
    }, [pathname]);

    return (
        <section className="print:hidden">
            <div className="w-full page-heading relative">
                {!!children ? (
                    children
                ) : (
                    <>
                        <div
                            className="h-full w-full"
                            style={{
                                background: `url('')`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "50% 50%"
                            }}
                        ></div>
                        <div className="overlay absolute top-0 right-0 bottom-0 left-0 opacity-60 bg-gradient-to-t from-darkBlack via-reddishBrown via-red-700 to-black"></div>
                    </>
                )}

                <Container className="absolute bottom-0 left-0 right-0 pb-20 flex flex-col justify-end  px-4 lg:px-0">
                    {!!heading && (
                        <Animate animateIn={"fadeIn"} duration={0.6}>
                            <h2 className="text-white text-5xl font-wwf max-w-xl drop-shadow-lg shadow-black banner-title">
                                {heading}
                            </h2>
                        </Animate>
                    )}

                    {!!description && (
                        <Animate animateIn={"fadeIn"} duration={0.8}>
                            <p className="max-w-3xl mt-3 font-light font-graphik_regular text-white opacity-90 text-base">
                                {description}
                            </p>
                        </Animate>
                    )}

                    {!!link && (
                        <Animate animateIn={"fadeIn"} duration={1}>
                            <div className="flex">
                                <Link
                                    to={link}
                                    className="group transition-all text-white bg-red-500 flex px-5 py-3 mt-5 rounded-md items-center hover:bg-red-600"
                                >
                                    <span className="mr-3 transition-all group-hover:mr-4 font-sans text-sm">
                                        LEARN MORE
                                    </span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14 5l7 7m0 0l-7 7m7-7H3"
                                        />
                                    </svg>
                                </Link>
                            </div>
                        </Animate>
                    )}
                </Container>
            </div>
        </section>
    );
};
export default PageHeading;
