import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    imageUrl: "",
    loading: false,
    data: []
};

export const getProjectHighlights = createAsyncThunk(
    "projectHighlights/getProjectHighlights",
    async (offset, thunkAPI) => {
        const res = await fetch(
            `${baseURL}/project-highlights/?page=${offset}&ordering=-date`
        ).then((data) => data.json());
        return res;
    }
);
export const projectHighlightsSlice = createSlice({
    name: "projectHighlights",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        newsPageHeadingImageUrl: (state, action) => {
            state.imageUrl = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectHighlights.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProjectHighlights.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getProjectHighlights.rejected, (state) => {
            state.loading = false;
        });
    }
});
export const { newsPageHeadingImageUrl } = projectHighlightsSlice.actions;
export const projectHighlightsSelector = (state) => state.projectHighlights;

export default projectHighlightsSlice.reducer;
