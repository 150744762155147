import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getFellowIndividualDetails = createAsyncThunk(
    "fellowIndividualDetails/getFellowIndividualDetails",
    async (slug, thunkAPI) => {
        const res = await fetch(`${baseURL}/fellow/${slug}`).then((data) =>
            data.json()
        );
        return res;
    }
);
export const fellowIndividualDetailsSlice = createSlice({
    name: "fellowIndividualDetails",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFellowIndividualDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getFellowIndividualDetails.fulfilled,
            (state, action) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(getFellowIndividualDetails.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const fellowIndividualDetailsSelector = (state) =>
    state.fellowIndividualDetails;

export default fellowIndividualDetailsSlice.reducer;
