import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: [],
};

export const getGuideline = createAsyncThunk(
    'guideline/getGuideline',
    async (thunkAPI) => {
        const res = await fetch(`${baseURL}/project-guidelines/`).then((data) => data.json());
        return res;
    }
)
export const guidelineSlice = createSlice({
    name: 'guideline',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getGuideline.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getGuideline.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.results;
        })
        builder.addCase(getGuideline.rejected, (state) => {
            state.loading = false;
        })
    },
});

export const guidelineSelector = (state) => state.guideline;

export default guidelineSlice.reducer;