import PageError from "../../components/pageerror/PageError";

const PageNotFound = () => {
  return (
    <>
      <PageError />
    </>
  );
};
export default PageNotFound;
