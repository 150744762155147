import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedCategory: "Our Team​",
    teamBoardSelection: "Team"
};

export const teamSlice = createSlice({
    name: "team",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        saveData: (state, action) => {
            state.selectedCategory = action.payload;
        },
        teamOrBoardOrMentor: (state, action) => {
            state.teamBoardSelection = action.payload;
        }
    }
});
export const { saveData, teamOrBoardOrMentor } = teamSlice.actions;

export const teamSelector = (state) => state.team;

export default teamSlice.reducer;
