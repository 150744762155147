import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = `${process.env.REACT_APP_API_SERVER_URL}`;

const initialState = {
    loading: false,
    data: []
};

export const getMemberIndividualDetails = createAsyncThunk(
    "memberIndividualDetails/getMemberIndividualDetails",
    async (slug, thunkAPI) => {
        const res = await fetch(`${baseURL}/ysb-fellow/${slug}`).then((data) =>
            data.json()
        );
        return res;
    }
);
export const memberIndividualDetailsSlice = createSlice({
    name: "memberIndividualDetails",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMemberIndividualDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getMemberIndividualDetails.fulfilled,
            (state, action) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(getMemberIndividualDetails.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const memberIndividualDetailsSelector = (state) =>
    state.memberIndividualDetails;

export default memberIndividualDetailsSlice.reducer;
